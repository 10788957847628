.flexslider {
	box-shadow: none;
	border-radius: 0;
	border: none;
}
.flexslider .slides img {
	height: auto;
}

.type-property {
	border: none;
	.thumbnail {
		width: 100% !important;
		height: auto;
		border: none;
		img {
			float: left;
			max-width: 100%;
			height: auto;
		}
	}
	.details {
		width: 100% !important;
	}
	.price {
		font-weight: 700;
		font-size: 1.4rem;
		margin: $smlspace;
	}
	
	ul {
		list-style: none;
		padding: 0;
	}
	.property_meta {
		ul > li {
			padding: 5px 0;
			&:not(:last-child){
				border-bottom: 1px solid darken($lightgrey, 5%);
			}
		}
	}
}
.properties {
	display: flex;
	margin: 2rem 0 !important;
	padding: 0;
	li {
		display: inline-block;
		width: 33% !important;
		margin: 0;
		clear: none !important;
		padding-top: 0 !important;
		background-color: darken($lightgrey, 5%);
		border: none !important; 
		margin-bottom: 5px !important;
		min-height: 55vh;
		&:not(:last-child){
			margin-right: 0.5rem !important;
		}
		.thumbnail {
			padding: 0;
			max-height: 200px;
			min-height: 200px;
			overflow-y: hidden;
			border-radius: 0;
			img {
				height: 100%;
				width: auto;

			}
		}
		.details {
			text-align: center;

		}
		.summary {
			display: none;
		}
		@include MQ(S){
			width: 100% !important;
		}
	}
}

.property_actions {
	ul {
		li {
			
			a {
				display: block;
				text-align: center;
				padding: 5px 15px; 
				border: 2px solid $brand-primary;
				color: $brand-primary;
				background: none;
				&:hover {
					text-decoration: none;
					background-color: $brand-primary;
					color: #fff;
					transition: $ease;
				}
			}
			&:not(:last-chil){
				a {
					margin-bottom: 10px;
				}
			}
		}
	}
 }

 .developments-list {
 	display: flex;
 	width: 100%;
 	justify-content: space-between;
 	flex-wrap: wrap;
 	.development-item {
 		float: left;
 		width: calc(50% - 1rem);
 		@include MQ(S){
 			width: 100%;
 		}
 		margin-bottom: 2rem;
 		.post-info {
 			background: $lightgrey;
 			padding: 2rem;
 			display: flex;
 			align-items: center;
 			justify-content: space-between;
 			h2 {
 				font-size: 2rem;
 				a {
 					color: $secondary;
 				}
 			}
 		}
 	}
 }

 .main, .single-development {
 	text-align: center;
 	padding: $bigspace;
 	h2 {
 		font-size: 2.4rem;
 		margin-bottom: 2rem;
 	}
 	.btn-primary {
 		margin-top: 2rem;
 	}
 }

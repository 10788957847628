$hero-height: 90vh;
$mob-height: 80vh;

.hero, .owl-carousel {
	height: $hero-height;
	background-size: cover;

	display: flex;
	justify-content: flex-start;
	align-items: flex-end;

	background-repeat: no-repeat;
	background-position: center;
	@include MQ(S){
		height: $mob-height;
	}
	.owl-item.active {
		.item {
			.item-content {
				@include fadeUp(1s);
			}
		}
	}
	.item {
		display: flex;
		align-items: flex-end;
		@include MQ(S){
			height: $mob-height;
		}
		.overlay {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: $transp-black;
		}
	}
	.hero-content, .owl-item > .item > .item-content {
		display: flex;
		align-items: flex-end;
		color: #fff;
		padding: 2rem;
		background: $transp-blue;
		width: 100%;
		justify-content: space-between;

		h2 {
			font-size: 3rem;
			font-weight: 600;
			justify-content: flex-start;
		}

		ul {
			list-style: none;
			font-weight: 500;
			padding: 0;
			margin-top: 1rem;
			li {
				display: block;
				&:not(:last-child){
					margin-bottom: 1rem;
				}
			}
		}
		.search-panel {
			padding: 30px;
			color: $brand-primary;
			width: 100%;
			background-color: #eee;
			margin-top: 50px;
			h2 {
				font-size: 1.2rem;
				margin-bottom: 30px;
			}
			.control {
				display: inline-block !important;
				select {
					background-color: #fff;
					border: none;
					border-radius: 0 !important;
					padding: 10px !important;
					box-shadow: $shadow;
					color: #999;
				}

			}
			.control-department {
				display: none !important;
			}
			label {
				margin-right: 5px;
				font-weight: 500;
			}
			input[type='submit']{
					border:none;
					background-color: $mint;
					color: $darkgrey !important;
					font-weight: 800;
					width: auto;
					&:hover {
						background-color: darken($mint, 10%);
						transition: $ease;
					}
				}
		}
	}

}
.landing-hero {
	display: flex;
	height: 100vh;
			@include MQ(S){
			height: $mob-height;
		}
	background-repeat: no-repeat;
	background-size: cover;
	align-items: flex-end;
	justify-content: flex-end !important;
	.hero-content {
		@include fadeFromRight(1s);
		animation-delay: 1s;
		display: inline-block;
		width: 40%;
		background-color: $brand-primary;
		color: #fff;
		padding: 2rem;
		h1 {
			font-size: 1.4rem;
			&:after {
				content: '';
				margin: 1rem 0;
				display: block;
				width: 50px;
				height: 4px;
				background-color: $text;
			}
		}
		h3 {
			font-size: 1rem;
			font-weight: 500;
			margin: 1rem 0;
		}
		input {
			display: block;
			background-color: #fff;


			&:not(:last-child){
				margin-bottom: 5px;
			}
		}
		input[type='submit']{
			border-radius: 0;
			background-color: $brand-primary;
			color: #fff !important;

		}
		.wpcf7-mail-sent-ok {
			padding: 10px;
			color: #fff;
			font-weight: 600;
			margin: 0;
			background-color: darken($mint, 20%);
		}
		@include MQ(S){
			width: 100%;
			padding: 0.5rem;
			padding-top: 1rem;
			border-radius: 0;
			form {
				display: none;
			}
		}
	}
	@include MQ(S){
		height: 100vh;
		background-position: center;
	}
}
.owl-carousel {

	// Styling items
	.owl-item.active {
		h2 {
			@include fadeUp(1s);
		}
	}
	.owl-item {
		height: $hero-height;
		background-color: $lightgrey;
		@include MQ(S){
			height: $mob-height;
		}
		.item {
			width: 100%;
			height: $hero-height;
			background-size: cover;
			@include MQ(S){
				height: $mob-height;
			}
			.item-content {
				z-index: 10000 !important;
				height: 100%;
				background: none;
				text-align: center !important;
				justify-content: center !important;
				align-items: center;
				flex-wrap: wrap;
				animation-delay: 0.8s;
				div {
					max-width: 50%;
					@include MQ(S){
						max-width: 100%;
					}
				}
				h1, h2 {
					font-size: 6rem;
					@include fadeUp(1s);
					@include MQ(S){
						font-size: 3rem;
					}
				}
				p {
					animation-delay: 2s;
					@include fadeUp(1s);
				}
				.btn-primary {
					margin-top: 2rem;
					animation-delay: 1s;
					@include fadeUp(1s);
				}
			}

		}
	}

	// Styling Next and Prev buttons
	.owl-nav {
		position: absolute;
		bottom: 0;
		right: 0;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		height: 100%;

		text-align: center;
		-webkit-tap-highlight-color: transparent;
		align-items: center;
		z-index: 0;
		@include MQ(S){
			display: none;
		}
		[class*='owl-'] {
			&:hover {
				background: lighten($secondary, 5%);

				text-decoration: none;
			}
		}
		.owl-prev, .owl-next {
			font-size: 0.9rem;
			background-color: darken($secondary, 10%);
			padding: 1rem 0.5rem;
			max-width: 100px;
			min-width: 100px;
			color: #fff;
			text-transform:uppercase;
		}
		.owl-prev {
			border-right: 1px solid $secondary;
			left: 0;
		}
		.owl-next {

			right: 0;
		}

	}

	// Styling dots
	.owl-nav.disabled + .owl-dots {
		margin-top: 10px;
	}

	.owl-dots {
		text-align: center;
		-webkit-tap-highlight-color: transparent;
		position: absolute;
		bottom: 20px;
		width: 100%;

		.owl-dot:before {
			display: inline-block;
			color: #fff;
			font-size: 1rem;
			font-weight: 300;
		    counter-increment:dots;
		    content: counter(dots, decimal-leading-zero);
		    @include MQ(S){
		    	display: block;
		    }
		}
	}
}

.bottom-strap {
	font-family: $second-font;
	height: 10vh;
	background: $secondary;
	color: #fff;
	margin-bottom: 2rem;
	@include MQ(S){
		height: 20vh;
	}
	ul {
		list-style: none;
		padding: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		flex-wrap: wrap;
		li {


			width: 25%;

			height: 100%;
			@include MQ(S){
				width: 50%;
			}
			a {
				display: flex;
				height: 100%;
				width: 100%;
				color: #fff;
				align-items: center;
				justify-content: center;
				text-align: center;
				&:hover {
					text-decoration: none;
					background: darken($secondary, 5%);
					transition: $ease;
				}
				&:not(last-child){
					border-right: 1px solid lighten($secondary, 10%);
				}
			}
			@include MQ(S){
				&:nth-child(1), &:nth-child(2){
					display: none;
				}
			}
		}
	}
}
$mini-carousel-height: 50vh;
.mini-carousel {
	position: relative;
	display: block !important;
	float: left;
	height: $mini-carousel-height !important;
	.owl-item, .item {
		height: $mini-carousel-height !important;
	}
	.owl-nav {
		align-items: flex-end;
	}
}

.in-carousel-image {
	object-fit: cover;
}

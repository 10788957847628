// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

textarea, input, .intl-tel-input {
	background:none;
	border: none;
	border-bottom: 2px solid $bronze;
	outline: none;
	padding: 5px;
	color: #fff;
	width: 100%;
	margin-bottom: 10px;
  input {
    border-bottom: none;
  }
	@include MQ(S){
		margin-bottom: 10px;
	}
}


// Enquiry form

.pp_content {
	padding: 20px !important;
}
.pp_inline {
	h2 {
		margin-top: 0;
		color: $secondary;
	}
}

.property-enquiry-form {
	label {
		display: inline-block;
		width: 50%;
	}
	input, textarea {
		width: 50%;
	}
	input[type='submit']{
		border-radius: 0;
		width: 100%;
		padding: 10px;
	}
}

// Responses

 div.wpcf7-mail-sent-ok {
 	border: none;
 	background-color: $mint;
 	color: $darkgrey;
 	padding: 20px;
 }


.country-list {
  background-color: $darkgrey !important;
}

.light-form {
  textarea, input, .intl-tel-input {
    border-bottom: none;
    background: $lightgrey;
    border: 1px solid darken($lightgrey, 10%);
    color: $darkgrey;
    padding: 0.5rem !important;
  }

  input[type="checkbox"] {
    width: auto;
    display: inline-block;
    margin-right: 0.5rem;
  }
  input[type="submit"] {
    background: $secondary;
    color: #fff;
    border: none;
    padding: 1rem;
  }
  input[type="file"] {
    width: auto;
    text-align: center;
    margin: 0 auto;
    background: none;
    border: none;
  }
}

html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*, *:after, *:before {
	box-sizing: border-box;
}

html, body {
	/* you need to set this to assign to the main element a min height of 100% */
	height: 100%;
	font-size: 100%;
	margin: 0 !important;
}

body {
	font: {
		family: $primary-font;
	}
	color: $text;
	font-weight: 300;
}

h1, h2, h3 {
	font-family: $second-font !important;
	font-weight: 200 !important;
	margin-top: 0;
}
h1 {
	font-size: 1.4rem;

}
h2 {
	font-size: 2rem;
}
h3 {
	font-size: 1rem;
	font-weight: 500;
}

p {
	line-height: 1.8rem;
}
.main {
	padding: $vsmall;
	h2 {
		font-size: 1.2rem;

	}
	.col-md-4 {
		border-left: 2px solid $secondary;
		padding-bottom: 20px;
	}
	ul {
		list-style: none;
		padding: 0;
		li {
			padding: 1rem 0;
			&:not(:last-child){
				border-bottom: 1px solid $grey;
			}
		}
	}
}
.fp-main {
	padding: $bigspace;
}
.center {
	text-align: center;
	margin-bottom: 2rem;
}

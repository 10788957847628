// Flex for extra sections
.extra {
	margin: $midspace;
	.child {
		min-height: 60vh;

		display: flex;
		background-size: cover;
		flex-grow: 1;
		align-items:center;
		justify-content:center;
		text-align: left;
		padding: 6rem;
		margin: 2rem 0;
	}
}
.extra, .service-panels {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: 100%;

	// Defined width panels
	.fw {
		width: 100%;
	}
	.half {
		width: 50%;
	}
	.quarter {
		width: 25%;
	}
	.three-quarters {
		width: 75%;
	}
	.third {
		width: 30%;
	}
	.two-thirds {
		width: 66.6%;
	}
}
.service-icon {
	width: 50px;
	height: auto;
	margin-bottom: 20px;
}
.service-panels {

	padding: $midspace;
	background-color: $lightgrey;
	align-items: flex-start;
	h3 {
		color: $secondary;
		font-size: 1.2rem;
	}
	.child {
		display: inline-block;
		&:not(:last-child){
			margin-right: 30px;
		}
	}
}
.request-callback {
	background-color: darken($secondary, 10%);
	padding: $smlspace;
	text-align: center;
	margin: 0;
	outline: none;
	.cb-form {
		padding: 40px;
		color: #fff;
	}
}
.page-header {
	position: relative;
	width: 100%;
	height: 40vh;
	display: flex;
	background: $secondary;
	margin: 0;
	padding: 2rem;
	align-items: flex-end;
	color: #fff;
	text-align: center;
	h1 {
		font-size: 3rem;
	}

}

// MOBILE MEDIA QUERY
@include MQ(S){
	.child {
		width: 100% !important;
		min-height: 40vh !important;
	}
}


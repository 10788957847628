
.action-make-enquiry, .button {
	margin-top: 20px;
}

.btn-primary, input[type='submit'] {
	border: none;
	background-color: $bronze;
	padding: 1rem 2rem;
	border-radius: 0;
	font-family: $second-font;
	font-size: 1.2rem;
	&:hover {
		background-color: darken($bronze, 10%);
		transition: $ease;
	}
}
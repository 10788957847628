footer {
	background-color: $darkgrey;
	padding: $midspace;

	.logo {
		width: calc(585px / 4);
		height: auto;
		background: none;
	}
	@include MQ(S){
		.footer-logo {
			text-align: center;
			margin: 1rem 0;
		}
	}
	a {
		color: $mint;
		font-weight: 500;
		&:hover {
			color: lighten($mint, 20%);
			transition: $ease;
			text-decoration: none;
		}
	}
	.btn-primary {
		background: none;
	}
	color: #fff;
	.credit {
		text-align: right;
		@include MQ(S){
			text-align: center;
		}
	}
	.center {
		text-align: center;
	}
	.footer-nav {
		list-style: none;
		padding: 0;
		margin: $smlspace;
		text-align: center;
		li {
			display: inline-block;
			&:not(:last-child){
				margin-right: 15px;
			}
		}
	}
}
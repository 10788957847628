// rem fallback - credits: http://zerosixthree.se/

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// center vertically and/or horizontally an absolute positioned element

@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
  }
}

// border radius

@mixin border-radius($radius:.25em) {
  border-radius: $radius;
}

// breakpoints

$S:     520px;   
$M:     768px;     
$L:     1170px;     

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (max-width: $S) { @content; } 
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; } 
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; } 
  }
}

// super light grid - it works with the .cd-container class inside style.scss

@mixin column($percentage, $float-direction:left) {
  width: 100% * $percentage;
  float: $float-direction;
}

@keyframes animatedBackground {
  0% { transform: scale(1); }
  100% { transform: scale(0.6); }
}

@keyframes pan {
  0% {
    background-size: 100%;
  }
  100% {
    background-size: 105%;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    bottom: -25px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes fadeFromRight {
  0% {
    opacity: 0;
    margin-left: -50vw;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}
@mixin fadeUp($time){
  position: relative;
  animation: fadeUp $time $bezier 1 both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

@mixin fadeFromRight($time){
  animation: fadeFromRight $time $bezier 1 both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

@mixin pan($time){
  animation: pan $time $bezier 1 both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

@mixin fade($time){
  animation: fade $time $bezier 1 both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.fadeUp {
  @include fadeUp(1s);
}

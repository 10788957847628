// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

.pp_content {
	padding: 40px;
}

.fp-main {
  @include MQ(S) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

// Variables

$h-height: 100px;

.logo {
	width: calc(585px / 2.25);
	height: auto;
	@include fade(2s);

}
.site-header {
	font-family: $second-font;
	position: absolute;
	width: 100%;
	z-index: 100;
	padding: 0 2rem;
	@include MQ(S){
		background: none;
		padding: 0;
	}
	ul {
		list-style: none;
		padding: 0;
	}
	.brand {
		color: #fff;
		font-size: 1.4rem;
		strong {
			font-weight: 600;
		}
		&:hover {
			text-decoration: none;
		}
	}
	.container-fluid {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	.left, .right, .center {
		min-height: $h-height;
		float: left;
		display: flex;
		align-items: center;
		justify-self: center;
	}
	.left, .right {
		
		width: 20%;
		@include MQ(S){
			width: 40%;
		}
		.phone {
			color: #fff;
			margin-left: 3rem;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.right {
		@include MQ(S){
			display: none;
		}
		justify-content: flex-end;
		ul {
			text-align: right;
			li {
				display: block;
				&:first-child {
					margin-bottom: 0.5rem;
				}
				.fa {
					margin-right: 0.5rem;
				}
				a:hover {
					.fa, strong {
						color: $bronze;
						transition: $ease;
					}
				}
			}
		}
	}
	.center {
		width: 60%;
		text-align: center;	
		justify-content: center;
		@include MQ(S){
			width: 86%;
			justify-content: flex-end;
		}
		.contacts {
			ul {
				list-style: none;
				padding: 0;
				li {
					display: inline-block;
					&:not(:last-child){
						margin-right: 15px;
					}
				}
			}
		}
		.menu-toggle {
			display: none;
			z-index: 110;
			@include MQ(S){
				float: right;
			}
		}
		.mn-active {
			right: -100vw;
			opacity: 0;
		}
		.main-navigation {
			z-index: 100;
			.close-i {
				display: none;
				width: 50px;
				height: 50px;
				background: none;
				z-index: 110;
				.icon-bar {
					display: block;
					height: 2px;
					width: 50px;
					background-color: #fff;
				}
			}
		}
		.btn-primary {
			display: inline-block;
			margin-left: 25px;
			float: left;
			background: none;
			border: 2px solid #fff;
			padding: 0 !important;
			a {
				
			
				padding: 0;
			}
			@include MQ(S){
				background: none;
				margin-left: 0;
				padding: 0;
				a {
					color: #fff !important;
				}
			}

		}
	}
}
.solid, .sticky {
	position: relative;
	top: 0 !important;
	background-color: $brand-primary;
	min-height: 100px;
}
.sticky {
	position: fixed;
	display: flex;
	min-height: 50px;
	height: 50px;
	align-items: center;
	top: 0;
	width: 100%;
	transition: $ease;
	.logo {
		width: calc(750px / 5);
		animation-delay: 0.6s;
		margin-top: 7px;
	}
	.navbar {
		display: flex;
		min-height: 50px;
		align-items: center;
	}
	.navbar-right {
		margin-top: 3px;
	}
}
.main-navigation, .navbar-nav {

	li {
		a {
			color: #fff;
			&:hover {
				color: $bronze;
				background: none;
				transition: $ease;
			}

		}
	}
}
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
	background: none;
}

.dropdown-menu {
	background-color: $brand-primary;
	border-radius: 0;
	min-width: 200px;
	top: 50px;
	box-shadow: none;
	border:none;
	li {
		a {
			padding: 10px 15px;
			&:hover {
				background-color: $secondary;
				color: #fff;
			}
		}
	}
}
ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: block;
    @include fade(1s);  
}
@include MQ(S){
	body {
		overflow-x: hidden;
	}
	.site-header {
		top: 0;
	}
	.site-header > .container {

		padding-right: 0;
		overflow-x: hidden;
	}
	.menu-toggle, .close-i {
		display: flex !important;
		align-items: center;
		justify-content: center;
		// margin-right: 15px;
		width: 50px;
		height: 50px;
		border-radius: 50px;
		outline: none;
		border: none;
	}
	.close-i {
		margin-right: 20px;
	}
	.logo {
		width: calc(585px / 4);
	}
	.navbar-nav {
		float: left !important;
		width: 100%;
		margin: 0;
		li {
			text-align: left;
		}
	}
	.main-navigation {
		padding: 20px;
		position: absolute;
		left: -100vw;
		top: 0;
		width: 100vw;
		height: 100vh;
		background-color: $darkgrey;
		opacity: 0;
		overflow-x: hidden;
		transition: $ease;
		.close-i {
			float: right;
			.icon-bar {
				&:nth-child(1n){
					transform: rotate(45deg);
				}
				&:nth-child(2n){
					transform: rotate(-45deg);
				}
			}
		}
	}
	.contacts {
		display: none;
	}
	.mn-active {
		left: 0;
		top: 0;
		opacity: 1 !important;
		height: 100vh;
		
		transition: $ease;
		
			.navbar-nav {
				ul {
					li {
						animation-delay: 1s;
						@include fadeUp(1s);
					}
				}
			}
		
	}
	.menu-toggle {
		background-color: $secondary;
		z-index: 120 !important;
		&:hover {
			background-color: $mint;
			transition: $ease;
		}
		.icon-bar {
			display: block;
			height: 2px;
			width: 20px;
			background-color: #fff;
			&:not(:last-child){
				margin-bottom: 5px;
			}
		}
	}
}